
	import { Component, Vue, Watch } from "vue-property-decorator";
	import { Route } from "vue-router";
	import Drag from "@/components/drag/drag.vue";
	import Hls from "hls.js";
	import Loading from "@/components/loading/Loading.vue";
	import { Icon, Message } from "view-design";

	@Component({
		components: {
			Drag,
			Loading,
			Icon
		}
	})
	export default class Elevator extends Vue {
		elevator:{ type:string, data:elevatorData[] }[] = [];
		showSmt:boolean = false;
		loading:boolean = true;
		readonly id:string = "elevatorCamera";
		private hls?:Hls;
		private MainLink:HTMLLinkElement = document.createElement("link");

		updateStream (uid:string):void {
			window.selected(uid);
		}

		renderCss () {
			less.render("@lightTitleBackground: rgba(0, green(@baseColor)/2, blue(@baseColor)/2, .6);.legends {background-color: @lightTitleBackground;}", {
				javascriptEnabled: true,
				modifyVars: {
					"@baseColor": this.$store.state.baseColor
				}
			}).then(data => {
				this.MainLink && this.MainLink.remove();
				const href:string = URL.createObjectURL(new Blob([ data.css ]));
				const link = document.createElement("link");
				link.href = href;
				link.rel = "stylesheet";
				document.head.appendChild(link);
				this.MainLink = link;
			});
		}

		mounted () {
			this.renderCss();
			window.elevator = (elevator:elevatorData) => {
				console.log(elevator);
				const value:elevatorData = JSON.parse(elevator.toString());
				const serial:string = value.mepsystem;
				const temp = this.elevator.find(t => t.type === serial);
				if (temp) {
					const id = value.id.toString();
					const temp2 = temp.data.find(t => t.id.toString() === id);
					if (temp2) {
						temp2.RunStation = value.RunStation;
						temp2.Lastfloor = value.Lastfloor;
						temp2.Troublestation = value.Troublestation;
					} else {
						temp.data.push(value);
					}
				} else {
					this.elevator.push({ type: value.mepsystem, data: [ value ] });
				}
			};
			// @ts-ignore
			const video:HTMLVideoElement = document.getElementById(this.id);
			if (Hls.isSupported()) {
				const hls = new Hls();
				hls.loadSource("");
				hls.attachMedia(video);
				hls.on(Hls.Events.MANIFEST_PARSED, () => {
					video.play();
				});
				this.hls = hls;
			}
			this.listeners = this.$store.subscribe((mutation) => {
				if (mutation.type === "updateLiveStream") {
					if (mutation.payload === "") {
						// @ts-ignore
						Message.info({
							content: "电梯摄像头关联缺失"
						});
						this.showSmt = false;
						return;
					}
					if (this.hls) {
						this.hls.stopLoad();
						this.hls.detachMedia();
					}
					const hls = new Hls();
					hls.loadSource(`/mag/hls/${ mutation.payload.split(",")[ 1 ] }/1/live.m3u8`);
					hls.attachMedia(video);
					this.showSmt = true;
					this.loading = true;
					hls.on(Hls.Events.MANIFEST_PARSED, () => {
						video.play();
						this.loading = false;
					});
					this.hls = hls;
				}
			});
		}

		beforeRouteLeave (from:Route, to:Route, next:Function) {
			// @ts-ignore
			window.elevator = undefined;
			this.listeners();
			this.MainLink.remove();
			next();
		}

		@Watch("$store.state.baseColor")
		onColorChange () {
			this.renderCss();
		}

		private listeners ():void {
		};
	}
